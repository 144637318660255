<template>
  <section>
    <loading v-bind:dialog="isLoading"></loading>
    <v-layout row wrap>
      <v-flex lg12>
        <alert
          v-bind:tipo="notificacion.tipo"
          v-bind:mensaje="notificacion.mensaje"
        ></alert>
        <v-spacer></v-spacer>
        <v-card v-show="!isLoading && notificacion.mensaje === ''">
          <v-toolbar card color="white">
            <v-flex xs12>
              <h3>Consultar historico de: {{ nombre_cli }}</h3>
            </v-flex>

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>

          <v-card-text>
            <div class="contenedorDeFiltros">
              <v-layout row wrap class="justify-center">
                <v-flex xs12 sm4 lg4 xl4>
                  <v-flex class="d-flex flex-row">
                    <v-btn
                      color="info"
                      block
                      title="Agregar Historico"
                      @click="AltaHCliente(id, 'AgregarHistorico')"
                    >
                      <v-icon> note_add </v-icon>
                      Agregar Historico
                    </v-btn>
                  </v-flex>
                </v-flex>
                
                    <!-- FILTRO DE TIPO-->
                    <v-flex xs12 sm4 lg4 xl4>
                            <v-text-field
                              placeholder="Tipo"
                              v-model="tipoFiltro"
                              type="text"
                            >
                          </v-text-field>
                      </v-flex>
                    <!-- FILTRO DE DESCRIPCION-->
                    <v-flex xs12 sm4 lg4 xl4>
                      <v-flex class="d-flex pa-0 pr-3">
                            <v-text-field
                                placeholder="Descripción"
                                v-model="descripcionFiltro"
                                type="text"
                              ></v-text-field>
                            </v-flex>
                      </v-flex>

                  
              </v-layout>
            </div>




            <v-divider></v-divider>

            <v-layout v-resize="onResize" column wrap>
              <v-data-table
                :headers="headers"
                :items="itemsFiltrados"

                :pagination.sync="pagination"
                :hide-headers="isMobile"
                :class="{ mobile: isMobile }"
                :rows-per-page-items="rowsperpageitems"
                :expand="false"
              >
                <template slot="items" slot-scope="props"> 
                  <tr v-if="!isMobile">
                    <td>{{ props.item.thc_descri }}</td>
                    <td>{{ props.item.cl2_fecha }}</td>
                    <td>


<!-- ########################################## Se presenta una descripcion corta 
                                                luego se expande con un boton   ######################################################################-->
                      <div
                        class="descripcion_cont"
                        v-if="props.item.cl2_auxiliar"
                      >
                              <div v-show="!show[props.item.cl2_item]">
                                {{ props.item.cl2_auxiliar }}
                              </div>
                              <div class="descripcion"
                                style="white-space: pre-wrap"
                                v-show="show[props.item.cl2_item]"
                              >
                                {{ props.item.cl2_descri }}
                              </div>

                              <div class="boton_exp">
                                <v-btn icon @click="toggleShow(props.item.cl2_item)">
                                  <v-icon>{{
                                    show[props.item.cl2_item]
                                      ? "expand_less"
                                      : "expand_more"
                                  }}</v-icon>
                                </v-btn>
                              </div>
                      </div>
                      <div  class="descripcion" v-else>
                        {{ props.item.cl2_descri }}
                      </div>
                    </td>

                    <td>{{ props.item.usu_descri }}</td>
                    <td class="notbreak">
                      <v-btn
                        icon
                        small
                        slot="activator"
                        @click="
                          EditarHCliente(
                            id,
                            props.item.cl2_item,
                            'ModificarHistorico'
                          )
                        "
                      >
                        <v-icon color="cyan">edit</v-icon>
                      </v-btn>
                    </td>
                  </tr>


                  <!--------------------------------------######  MOBILE ###----------------------------------------------------------------------------------------->
                  <tr v-else>
                    <td>
                      <ul class="flex-content">
                        <li class="flex-item" data-label="Tipo">
                          {{ props.item.thc_descri }}
                        </li>
                        <li class="flex-item" data-label="Fecha">
                          {{ props.item.cl2_fecha }}
                        </li>

                        <li
                          class="flex-item"
                          data-label="Descripción"
                          v-if="props.item.cl2_auxiliar"
                        >
                          <template v-if="!show[props.item.cl2_item]">
                            
                            {{ props.item.cl2_auxiliar }}
                              <v-btn
                                icon
                                @click="toggleShow(props.item.cl2_item)"
                              >
                                <v-icon>expand_more</v-icon>
                              </v-btn>
                           
                          </template>
                          <template v-else>
                            <v-expand-transition>
                              <div style="white-space: pre-wrap">
                                {{ props.item.cl2_descri }}
                                <div class="descripcion_cont">
                                  <v-btn
                                    icon
                                    @click="toggleShow(props.item.cl2_item)"
                                  >
                                    <v-icon>expand_less</v-icon>
                                  </v-btn>
                                </div>
                              </div>
                            </v-expand-transition>
                          </template>
                        </li>
                        <li class="flex-item" data-label="Descripción" v-else>
                          {{ props.item.cl2_descri }}
                        </li>
                        <li class="flex-item" data-label="Usuario Alta">
                          {{ props.item.usu_descri }}
                        </li>
                        <li class="flex-item" data-label="Acciones">
                          <!--boton para modificar un historico de cliente-->
                          <v-btn
                            icon
                            slot="activator"
                            @click="
                              EditarHCliente(
                                id,
                                props.item.cl2_item,
                                'ModificarHistorico'
                              )
                            "
                          >
                            <v-icon color="cyan">edit</v-icon>
                          </v-btn>
                        </li>
                        <li class="enter"></li>
                      </ul>
                    </td>
                  </tr>



                </template>
              </v-data-table>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
//consumo de API
import ClienteService from "@/services/ClienteServices";
import HistoricoServices from "@/services/HistoricoServices";
//frontend
import loading from "@/components/share/Loading.vue";
import alert from "@/components/alert/alert.vue";
import DataTableMixin from "@/mixins/DataTableMixin";

export default {
  name: "ConsultarHistorico",
  mixins: [DataTableMixin],
  data() {
    return {

      //filtro -> prueba
      tipoFiltro: '',
      descripcionFiltro: '',



      historia: [],
      isLoading: false,
      notificacion: { mensaje: "", tipo: "error" },
      nombre_cli: "",
      pagination: { sortBy: "cl2_codthc" },
      isMobile: false,
      show: {}, //mostrar la descripcion completa
      descripcion: "",
      /*
                * cl2_item => autoincremental de 1 a n por cliente
                * cl2_codthc => tipo histórico... acá me parece que tendría que hacerles una ruta en la api que les devuelva los tipos de historia que hay
                * cl2_fecha => fecha de la historia, la selecciona el usuario al crearla
                * cl2_descri => texto de la historia, lo carga el usuario, tipo textarea porque puede escribir mucho
                * cl2_fecalt => fecha y hora de alta, es automático
                * cl2_usualt => codigo de usuario

            */
      headers: [
        { text: "Tipo", value: "thc_descri" },
        { text: "Fecha", value: "cl2_fecha" },
        { text: "Descripción", value: "cl2_descri" },
        { text: "Usuario Alta", value: "usu_descri" },
        { text: "Acciones", value: "" },
      ],
    };
  },
  components: {
    loading,
    alert,
  },
  props: {
    id: {
      type: Number,
      require: true,
    },
  },
  methods: {
    AltaHCliente(id, nombreRuta) {
      this.$router.push({ name: nombreRuta, params: { id: id } });
    },
    toggleShow(itemId) {
      this.$set(this.show, itemId, !this.show[itemId]);
    },
    EditarHCliente(id, item, nombreRuta) {
      this.$router.push({ name: nombreRuta, params: { id: id, item: item } });
    },
    async Cargar() {
      this.isLoading = true;
      const curcliente = await ClienteService.getbyID(this.id);
      if (curcliente.cli_codigo < 1 || curcliente === "") {
        this.notificacion.mensaje = "Inserte ID valido";
        this.isLoading = false;
        return;
      }
      this.notificacion.mensaje = "";
      this.nombre_cli = curcliente.cli_razsoc;

      await HistoricoServices.consultar(this.id)
        .then((res) => {
          this.historia = res;
          //console.log(this.historia);
          this.historia.forEach((element, indice) => {
            const fecha = new Date(element.cl2_fecha);
            const dia = fecha.getDate();
            const mes = fecha.getMonth() + 1;
            const anio = fecha.getFullYear();
            const regex = /\n/;

            // dd/mes/anio

            element.cl2_fecha = `${dia}/${mes}/${anio}`;

            //si la descripcion tiene saltos de linea
            if (regex.test(element.cl2_descri)) {
              const desc_enter = element.cl2_descri.split("\n");
              console.log(desc_enter);

              //si el primer elemento es mayor a 50
              if (desc_enter[0].length >= 50) {
                this.historia[indice] = {
                  ...element,
                  cl2_auxiliar: desc_enter[0].slice(0, 50) + "...",
                };
              } else {
                this.historia[indice] = {
                  ...element,
                  cl2_auxiliar: desc_enter[0] + "...",
                };
              }
            }
            //a cada descripcion que supere los 50 se le agraga una descripcion corta (auxiliar)
            else if (element.cl2_descri.length >= 50) {
              this.historia[indice] = {
                ...element,
                cl2_auxiliar: element.cl2_descri.slice(0, 50) + "...",
              };
            }
          });
          console.log(this.historia);
          return this.historia;
        })
        .catch((err) => {
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar los registros.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.Cargar();
  },
  watch: {
    id() {
      this.Cargar();
    },
  },
  computed: {
    //filtro por tipo y descripcion
    itemsFiltrados() {
      return this.historia.filter((item) => {
        const tipoMatch =
          item.thc_descri.toLowerCase().indexOf(this.tipoFiltro.toLowerCase()) !== -1;
        const descripcionMatch =
          item.cl2_descri.toLowerCase().indexOf(this.descripcionFiltro.toLowerCase()) !== -1;
        return tipoMatch && descripcionMatch;
      });
    },
  },

};
</script>

<style scoped>
.notbreak {
  display: flex;
  align-items: center;
}

.notbreak .v-btn {
  display: flex;
  margin: 4px;
}


.boton_exp {
  display: flex;
  justify-content: flex-end;
}

.contenedorDeFiltros{
  margin: 0;
  display: flex;
  width: 100%;
  max-width: initial;
  justify-content: center;
}

.descripcion_cont {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  align-items: center;
}

.flexx{
  padding: 0;
}

.descripcion{
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  word-wrap: break-word;
  text-rendering: auto;
}


</style>
