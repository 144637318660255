<template>
    <v-container grid-list-xl fluid>
        <div v-if="IsAdmin">
            <ConsultarHistorico  v-if="IsAdmin" :id="id" />
        </div>
    </v-container>
</template>


<script>
import userService from "@/services/UserServices";
import ConsultarHistorico from "@/components/cliente/ConsultarHistoricoCliente.vue";
export default {
    props: {
        id: {
            type: Number,
            require: true
        }
    },
    components: {
        ConsultarHistorico
    },
    computed: {
        IsAdmin() {
            return userService.IsAdmin();
        }
    }
}
</script>

